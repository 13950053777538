import { getBlacklist,removeBlacklist }  from '@/api'

const state = {
    blacklistInfo:{}
}
const mutations = {
    GETBLACKLIST(state,blacklistInfo){
          state.blacklistInfo = blacklistInfo
    }
}
const actions = {
    //获取黑名单数据
    async getBlacklist({commit},{ num,page }){
          let result = await getBlacklist(num,page)
          if(result.code==200){
                commit('GETBLACKLIST',result.data)    
          }
    },

    //移除黑名单
    async removeBlacklist({commit},buyerid){
          let result =await removeBlacklist(buyerid)
          if(result.code==200){
               return 'ok'
          }else{
              return Promise.reject(new Error(result.msg))
          }
    }
}
const getters = {

}


export default {
      state,
      mutations,
      actions,
      getters
}