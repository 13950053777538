import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

//引入小仓库
import user from './user'
import home from './home'
import bindStore from './Store_Management'
import blacklist from './blacklist'
import priceDetails from './priceDetails'
import accountDetails from './Account_Management'
import complaint from './complaint'
import publish from './publish'
import balance from './Balance_Management'
import invitation from './invitation'
import advancePayment from './advancePayment'

//对外暴露Store类的一个实例
export default new Vuex.Store({
      //实现vuex仓库模块式开发存储数据
        modules:{
            user,
            home,
            bindStore,
            blacklist,
            priceDetails,
            accountDetails,
            complaint,
            balance,
            publish,
            invitation,
            advancePayment
        }   
})