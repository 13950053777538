import { getStoreInfo,getBindStoreAddress,getProvinceData,getCityData,getAreaData,getPlatformName,addBindStore,editRepurchaseInterval,getStoreFHInfo,editStoreFHInfo } from '@/api'
const state = {
      storeInfo:{},
      bindStoreAddress:{},
      provinceData:[],
      cityData:[],
      areaData:[],
      platformList:[],
      storeFHInfo:{}
};
const mutations = {
      GETSTOREINFO(state,storeInfo){
            state.storeInfo = storeInfo
      },
      GETBINDSTOREADDRESS(state,bindStoreAddress){
            state.bindStoreAddress = bindStoreAddress
      },
      GETPROVINCEDATA(state,provinceData){
             state.provinceData = provinceData
      },
      GETCITYDATA(state,cityData){
             state.cityData = cityData
      },
      GETAREADATA(state,areaData){
             state.areaData = areaData
      },
      GETPLATFORMNAME(state,platformList){
             state.platformList = platformList
      },
      GETSTOREFHINFO(state,storeFHInfo){
             state.storeFHInfo = storeFHInfo
             state.provinceData = storeFHInfo.provincearr
             state.cityData = storeFHInfo.cityarr
             state.areaData = storeFHInfo.townarr
      }
};
const actions = {
      //获取店铺列表数据
      async getStoreInfo({commit},{ num,page }){
          let result = await getStoreInfo(num,page)
          if(result.code==200){
                commit('GETSTOREINFO',result.data)
          }
      },

      //获取绑定店铺地址
      async getBindStoreAddress({commit},{shopsid}){
            let result = await getBindStoreAddress(shopsid)
            if(result.code==200){
                  commit('GETBINDSTOREADDRESS',result.data)
            }
      },

      //获取省份数据
      async getProvinceData({commit}){
           let result = await getProvinceData()
           if(result.code==200){
                  commit('GETPROVINCEDATA',result.data)
           }
      },

      //获取城市数据
      async getCityData({commit},bh){
            let result = await getCityData(bh)
            if(result.code==200){
                  commit('GETCITYDATA',result.data)
            }
      },

      //获取区县数据
      async getAreaData({commit},bh){
            let result = await getAreaData(bh)
            if(result.code==200){
                  commit('GETAREADATA',result.data)
            }
      },

      //获取平台名称数据
      async getPlatformName({commit}){
          let result = await getPlatformName()
          if(result.code==200){
                commit('GETPLATFORMNAME',result.data)
          }
      },

      //添加绑定店铺
      async addBindStore({commit},data){
            let result = await addBindStore(data)
            if(result.code==200){
                   return 'ok'
            }else{
                  return Promise.reject(new Error(result.msg))
            }
      },
      
      //修改复购间隔
      async editRepurchaseInterval({commit},data){
            let result = await editRepurchaseInterval(data)
            if(result.code==200){
                  return 'ok'
            }else{
                  return Promise.reject(new Error(result.msg))
            }
      },

      //获取商铺发货信息
      async getStoreFHInfo({commit},shopsid){
            let result = await getStoreFHInfo(shopsid)
            if(result.code==200){
                  commit('GETSTOREFHINFO',result.data)
                  return 'ok'
            }else{
                  return Promise.reject(new Error(result.msg))
            }
      },

      //修改商铺发货信息
      async editStoreFHInfo({commit},data){
           let result = await editStoreFHInfo(data)
           if(result.code==200){ 
                 return 'ok'
           }else{
                 return Promise.reject(new Error(result.msg))
           }
      }
};
const getters = {
      //获取店铺列表数据
      getStoreList(){
            return state.storeInfo.shopslist||[]
      },
      //获取用户信息
      getUserInfo(){
            return state.storeInfo.user||{}
      },
      //获取分页数组
      getPageSizes(){
            return state.storeInfo.page_sizes||[]
      },
      //获取数据总条数
      getTotal(){
            return state.storeInfo.total||0
      },
};

export default {
      state,
      mutations,
      actions,
      getters
}