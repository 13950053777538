import { getPublishTaskType,changeTaskType,getTaskStoreList,getTaskCondition,publishTaskFirstModuleSubmit,getPaidValueAddedServices,publishTaskSecondModuleSubmit,publishTaskThirdModuleSubmit,getTaskCostDetails,orderPayment } from '@/api'

const state = {
    publishTaskTypeInfo:{},
    taskClassification:[],
    taskStoreList:[],
    taskConditionInfo:{},
    firstBhCode:'', //第一模块提交后获取的编号
    addedServicesList:[],
    secondBhCode:'',//第二模块提交后获取的编号
    thirdBhCode:'',//第三模块提交后获取的编号
    taskCostDetails:{},//任务费用信息
}

const mutations = {
    GETPUBLISHTASKTYPE(state,publishTaskTypeInfo){
            state.publishTaskTypeInfo = publishTaskTypeInfo,
            state.taskClassification = publishTaskTypeInfo.data2,
            state.taskStoreList = publishTaskTypeInfo.data3
    },
    CHANGETASKTYPE(state,taskClassification){
            state.taskClassification = taskClassification
    },
    GETTASKSTORELIST(state,taskStoreList){
            state.taskStoreList = taskStoreList
    },
    GETTASKCONDITION(state,taskConditionInfo){
            state.taskConditionInfo = taskConditionInfo
    },
    PUBLISHTASKFIRSTMODULESUBMIT(state,firstBhCode){
            state.firstBhCode = firstBhCode
    },
    GETPAIDVALUEADDEDSERVICES(state,addedServicesList){
            state.addedServicesList = addedServicesList
    },
    PUBLISHTASKSECONDMODULESUBMIT(state,secondBhCode){
            state.secondBhCode = secondBhCode
    },
    PUBLISHTASKTHIRDMODULESUBMIT(state,thirdBhCode){
            state.thirdBhCode = thirdBhCode
    },
    GETTASKCOSTDETAILS(state,taskCostDetails){
            state.taskCostDetails = taskCostDetails
    }
}

const actions = {
     //获取发布任务类型
     async getPublishTaskType({commit}){
         let result = await getPublishTaskType()
         if(result.code==200){
               commit('GETPUBLISHTASKTYPE',result.data)
         }
     },

     //切换任务类型
     async changeTaskType({commit},id){
        let result = await changeTaskType(id)
        if(result.code==200){
              commit('CHANGETASKTYPE',result.data)
        }
     },

     //获取所属任务下的店铺列表
     async getTaskStoreList({commit},{tasktypeid}){
        let result = await getTaskStoreList(tasktypeid)
        if(result.code==200){
               commit('GETTASKSTORELIST',result.data)
        }
     },

     //获取发布模块一的商品筛选信息
     async getTaskCondition({commit}){
        let result = await getTaskCondition()
        if(result.code == 200){
              commit('GETTASKCONDITION',result.data)
        }
     },

     //提交发布模块一的数据
     async publishTaskFirstModuleSubmit({commit},formData){
          let result = await publishTaskFirstModuleSubmit(formData)
          if(result.code==200){
                 commit('PUBLISHTASKFIRSTMODULESUBMIT',result.data)
                 return 'ok'
          }else{
                 return Promise.reject(new Error(result.msg))
          }
     },

     //获取付费增值业务
     async getPaidValueAddedServices({commit}){
           let result = await getPaidValueAddedServices()
           if(result.code==200){
                 commit('GETPAIDVALUEADDEDSERVICES',result.data)
           } 
     },

     //提交打不模块二的数据
     async publishTaskSecondModuleSubmit({commit},formData){
          let result = await publishTaskSecondModuleSubmit(formData)
          if(result.code==200){
               commit('PUBLISHTASKSECONDMODULESUBMIT',result.data)
               return 'ok'
          }else{
                return Promise.reject(new Error(result.msg))
          }
     },

     //提交打不模块三的数据
     async publishTaskThirdModuleSubmit({commit},formData){
        let result = await publishTaskThirdModuleSubmit(formData)
        if(result.code==200){
             commit('PUBLISHTASKTHIRDMODULESUBMIT',result.data)
             return 'ok'
        }else{
              return Promise.reject(new Error(result.msg))
        }
     },

     //获取任务费用明细
     async getTaskCostDetails({commit},bh){
         let result = await getTaskCostDetails(bh)
         if(result.code==200){
             commit('GETTASKCOSTDETAILS',result.data)
         }
     },

     //立即支付
     async orderPayment({commit},bh){
         let result =await orderPayment(bh)
         if(result.code==200){
               return 'ok'
         }else{
                return Promise.reject(new Error(result.msg))
          }
     }

}

const getters = {}

export default {
    state,
    mutations,
    actions,
    getters
}