import { getInvitationInfo } from '@/api'
const state = {
    invitationInfo:{}
};
const mutations = {
    GETINVITATIONINFO(state,invitationInfo){
           state.invitationInfo = invitationInfo
    }
};
const actions = {
   async getInvitationInfo({commit}){ //获取邀请数据
          let result = await getInvitationInfo()
          if(result.code==200){
             commit("GETINVITATIONINFO",result.data)
          }
   }
};
const getters = {}

export default {
    state,
    mutations,
    actions,
    getters
}
