import { getphoneRegister, getSmscode, getUserLogin, getphoneLogin, getphoneForget, getUserInfo, logout } from '@/api'
import { setToken,getToken,removeToken } from '@/utils/token'

const state = {
    userInfo: {},
    token:getToken(), //没存是NULL，存了就直接拿
};
const mutations = {
    GETUSERLOGIN(state,userInfo) {
        state.userInfo = userInfo,
        state.token = userInfo.token
    },
    GETUSERINFO(state,userInfo){
        state.userInfo = userInfo
    },
    CLEAR(state){//清除本地数据
         //把仓库中用户信息清空
         state.token = '';
         state.userInfo = {};
         //本地存储数据清空
         removeToken()
    }

};
const actions = {
    //发送短信
    async getSmscode({commit}, user) {
        let result = await getSmscode(user)
        if(result.code==200){
            return 'ok'
        }else{
            return Promise.reject(new Error(result.msg))
        }
    },

    //账号密码登录
    async getUserLogin({commit}, user) {
        let result = await getUserLogin(user)
        if(result.code==200){
            commit("GETUSERLOGIN", result.data)
            setToken(result.data.token);
            return 'ok'
        }else{
            return Promise.reject(new Error(result.msg))
        }
    },

    //验证码登录
    async getphoneLogin({commit},phone){
        let result = await getphoneLogin(phone)
        if(result.code==200){
            commit("GETUSERLOGIN", result.data)
            setToken(result.data.token);
            return 'ok'
        }else{
            return Promise.reject(new Error(result.msg))
        }
    },

    //账户注册
    async getphoneRegister({ commit }, data) {
        let result = await getphoneRegister(data)
        if(result.code==200){
            return 'ok'
        }else{
            return Promise.reject(new Error(result.msg))
        }
    },

    //找回密码
    async getphoneForget({ commit }, data) {
        let result = await getphoneForget(data)
        if(result.code==200){
            return 'ok'
        }else{
            return Promise.reject(new Error(result.msg))
        }
    },

    //获取用户部分信息
    async getUserInfo({commit}){
        let result = await getUserInfo()
        if(result.code==200){
            commit('GETUSERINFO',result.data)
            return 'ok'
        }else{
            return Promise.reject(new Error(result.msg))
        }
    },

    //退出登录
    async logout({ commit }){
        let result = await logout()  
        if(result.code==200){
            commit('CLEAR')
            return 'ok'
        }else{
           return Promise.reject(new Error(result.msg))
        }
    }
};

const getters = {

}

export default {
    state,
    mutations,
    actions,
    getters
}