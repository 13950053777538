import { getAccountDetailsData } from '@/api'

const state = {
    accountdetails:{}
}
const mutations = {
    GETACCOUNTDETAILSDATA(state,accountdetails){
            state.accountdetails = accountdetails
    }
}
const actions = {
    //获取账户明细数据
    async getAccountDetailsData({commit},data){
         let result = await getAccountDetailsData(data)
         if(result.code==200){
                commit('GETACCOUNTDETAILSDATA',result.data)
         }
    }
}
const getters = {}

export default{
    state,
    mutations,
    actions,
    getters
}