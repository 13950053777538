import { getMenuList,getHeaderInfo,getNotificationBarInfo,getNotificationContent,allRead } from '@/api'


const state = {
       menuList:[], //左侧菜单列表
       headerInfo:{},//头部菜单数据
       notificationBarInfo:{},//通知栏信息
       notificationContent:{},//公告内容信息
};
const mutations = {
       GETMANULIST(state,menuList){
              state.menuList = menuList
       },
       GETHEADERINFO(state,headerInfo){
              state.headerInfo = headerInfo
       },
       GETNOTIFICATIONBARINFO(state,notificationBarInfo){
              state.notificationBarInfo = notificationBarInfo
       },
       GETNOTIFICATIONCONTENT(state,notificationContent){
              state.notificationContent = notificationContent
       }
};
const actions = {
      async getMenuList({commit}){ //获取左侧菜单数据
             let result = await getMenuList()
             if(result.code==200){
                 commit("GETMANULIST",result.data)
             }
      },

      async getHeaderInfo({commit}){ //获取头部菜单数据
            let result = await getHeaderInfo()
            if(result.code==200){
                commit("GETHEADERINFO",result.data)
            }
      },

      async getNotificationBarInfo({commit}){ //获取通知栏信息
           let result = await getNotificationBarInfo()
           if(result.code==200){
                commit("GETNOTIFICATIONBARINFO",result.data)
           }
      },

      async getNotificationContent({commit},id){
           let result = await getNotificationContent(id)
           if(result.code==200){
               commit("GETNOTIFICATIONCONTENT",result.data)
               return 'ok'
           }else{
               return Promise.reject(new Error(result.msg))
           }
      },

      async allRead({commit},id){
           let result = await allRead(id)
           if(result.code==200){
               return 'ok'
           }else{
               return Promise.reject(new Error(result.msg))
           }
      },
};
const getters = {}

export default {
       state,
       mutations,
       actions,
       getters
}
