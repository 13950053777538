import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import store from '@/store'
import '@/plugins/element'
import '@/assets/css/global.less'
import '@/assets/css/theme.less'
//引入复制插件
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

//全局组件
import Breadcrumb from '@/components/Breadcrumb/breadcrumb'
import Title from '@/components/Title/title'

Vue.config.productionTip = false //阻止生产消息


Vue.component(Breadcrumb.name, Breadcrumb)
Vue.component(Title.name, Title)


new Vue({
  render: h => h(App),
  //全局事件总线$bus配置
  beforeCreate() {
    Vue.prototype.$bus = this
  },
  router,
  store
}).$mount('#app')
