import { getPriceDetailsData } from '@/api'

const state = {
    priceDetailsData:{}
}
const mutations = {
    GETPRICEDETAILSDATA(state,priceDetailsData){
            state.priceDetailsData = priceDetailsData
    }
}
const actions = {
    async getPriceDetailsData({commit},type){
         let result = await getPriceDetailsData(type)
         if(result.code==200){
               commit('GETPRICEDETAILSDATA',result.data)
         }
    }
}
const getters = {}


export default {
    state,
    mutations,
    actions,
    getters
}