import { getComplaintData,getBhAndLimit,complaintSubmit,complaintNotSubmit,deleteComplaintImage } from '@/api'

const state = {
    complaintInfo:{},
    bhAndLimitInfo:{}
}
const mutations = {
      GETCOMPLAINTDATA(state,complaintInfo){
             state.complaintInfo = complaintInfo
      },
      GETBHANDLIMIT(state,bhAndLimitInfo){
             state.bhAndLimitInfo = bhAndLimitInfo
      },
}
const actions = {
       //获取意见反馈（投诉）数据
       async getComplaintData({commit},{ num,page }){
        let result = await getComplaintData(num,page)
        if(result.code==200){
            commit('GETCOMPLAINTDATA',result.data)
        }
       },

       //获取上传反馈时的编号和图片数量
       async getBhAndLimit({commit}){
            let result = await getBhAndLimit()
            if(result.code==200){
                 commit('GETBHANDLIMIT',result.data);
                 return 'ok'
            }else{
                 return Promise.reject(new Error(result.msg))
            }
       },

       //提交反馈（投诉）数据
       async complaintSubmit({commit},{describe,bh}){
           let result = await complaintSubmit(describe,bh)
           if(result.code==200){
                return 'ok'
           }else{
                return Promise.reject(new Error(result.msg))
           }
       },

       //关闭反馈（不提交）
       async complaintNotSubmit({commit},{bh}){
          let result = await complaintNotSubmit(bh)
          if(result.code==200){
               return 'ok'
          }else{
               return Promise.reject(new Error(result.msg))
          }
       },

       //删除反馈（投诉）已上传的图片
       async deleteComplaintImage({commit},{bh,id}){
          let result = await deleteComplaintImage(bh,id)
          if(result.code==200){
               return 'ok'
          }else{
               return Promise.reject(new Error(result.msg))
          }
       }

}
const getters = {}


export default {
    state,
    mutations,
    actions,
    getters
}