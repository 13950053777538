import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
//引入store
import store from '@/store'

//使用插件
Vue.use(VueRouter)


const router = new VueRouter({
    // mode: 'history',
    routes,
    //滚动行为
    scrollBehavior (to, from, savedPosition) {
        //返回的y=0,代表的滚动条在最上方
        return { y : 0 }
    }
})

//全局守卫：前置守卫
router.beforeEach(async (to,from,next)=>{
     let token = store.state.user.token 
     let mot = store.state.user.userInfo.mot

     if(token){
          if(to.path=='/login'||to.path=='/register'||to.path=='/forget'||to.path=='/'){
                next(false)
          }else{
                if(mot){
                      next()
                }else{
                    //没有用户信息,深发action让仓库存储用户信息在跳转        
                    try{
                        //获取用户信息成功
                        await store.dispatch('getUserInfo')
                        //放行
                        next()
                    }catch(error){
                         //token失效了获取不到用户信息，重新登录
                         //清除token
                         await store.dispatch('logout')
                         next('/login')
                    }
                }
          }
     }else{
        if(to.path=='/login'||to.path=='/register'||to.path=='/forget'||to.path=='/'){
              next()
        }else{
              next('/login')
        }
     }
})

export default router