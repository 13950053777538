// 二次封装axios
import axios from 'axios'
import router from '@/router'
import nprogress from 'nprogress' //引入进度条
import "nprogress/nprogress.css" // 引入进度条样式
import { getToken } from '@/utils/token'

//根据环境变量区分接口默认地址（需要详细处理，后人请开启编辑）
// switch(process.env.NODE_ENV){
//     case "production":
//          axios.defaults.baseURL = "https://www.soso9.com";
//          break;
//     case "test":
//          axios.defaults.baseURL = "http://192.168.1.125:8080";
//          break;
//     default:
//         axios.defaults.baseURL = "http://127.0.0.1:8080";
//         break;
// }


//创建一个axios实例
const requests = axios.create({
     //  baseURL:"/api",  //基础路径
      timeout:5000,    //设置请求超时的时间
      withCredentials:true //是否允许携带凭证（跨域）
})


//请求拦截器
requests.interceptors.request.use((config) => {
     nprogress.start()
     let token = getToken()
     token&&(config.headers.Authorization = token) //携带token
    // config.headers.Authorization = getToken()
     return config;
},(error) =>{
     return Promise.reject(error)
})


//响应拦截器
requests.interceptors.response.use((res) => {
    nprogress.done()
    return res.data
},(error)=>{
        //响应失败回调
        return Promise.reject(error)
})



//需要详细处理，后人请开启编辑
// requests.interceptors.response.use((res) => {
//     nprogress.done()
//     return res.data
// },(error)=>{
//     let { response } = error
//     if(response){//服务器有返回结果
//         switch(response.status){
//              case 401: //权限（未登录）
//                  break;
//              case 403: //服务器拒绝执行（token过期）
//                  break;
//              case 404: //找不到页面
//                  break;
//         }
         
//     }else{//服务器没有返回结果
//         if(!window.navigator.onLine){ //断网处理：可以跳转到断网页面
//               return;
//         }
//         //响应失败回调
//         return Promise.reject(error)
//     }
// })




export default requests