import { getRechargeRecord,getRechargeQRCode,QRCodeRecharge,getVerificationCode,submitBindingAlipay,getWithdrawalRecord,getTXVerificationCode,withdrawal } from '@/api'

const state = {
    rechargeRecord:{},
    codeInfo:{},
    verificationCode:'',
    withdrawalRecordInfo:{},
    txVerificationCode:'',
}
const mutations = {
    GETRECHARGERECORD(state,rechargeRecord){
             state.rechargeRecord = rechargeRecord
    },
    GETRECHARGEQRCODE(state,codeInfo){
             state.codeInfo = codeInfo
    },
    GETVERIFICATIONCODE(state,verificationCode){
             state.verificationCode = verificationCode
    },
    GETWITHDRAWALRECORD(state,withdrawalRecordInfo){
             state.withdrawalRecordInfo = withdrawalRecordInfo
    },
    GETTXVERIFICATIONCODE(state,txVerificationCode){
             state.txVerificationCode = txVerificationCode
    }
}
const actions ={
    //获取充值记录
    async getRechargeRecord({commit},{num,page}){
         let result = await getRechargeRecord(num,page)
         if(result.code==200){
              commit('GETRECHARGERECORD',result.data)
         }
    },

    //获取充值二维码和余额
    async getRechargeQRCode({commit}){
         let result = await getRechargeQRCode()
         if(result.code==200){
               commit('GETRECHARGEQRCODE',result.data)
         }
    },

    //二维码充值
    async QRCodeRecharge({commit},{orderbh}){
        let result = await QRCodeRecharge(orderbh)
        if(result.code==200){
               return 'ok'
        }else{
               return Promise.reject(new Error(result.msg))
        }
    },

    //获取提现记录
    async getWithdrawalRecord({commit},{num,page}){
        let result = await getWithdrawalRecord(num,page)
        if(result.code==200){
            commit('GETWITHDRAWALRECORD',result.data)
        }
    },

    //获取绑定支付宝手机验证码
    async getVerificationCode({commit},{mot}){
        let result = await getVerificationCode(mot)
        if(result.code==200){
              commit('GETVERIFICATIONCODE',result.data)
              return 'ok'
        }else{
              return Promise.reject(new Error(result.msg))
        }
    },

    //绑定支付宝账户
    async submitBindingAlipay({commit},data){
        let result = await submitBindingAlipay(data)
        if(result.code==200){
            return 'ok'
        }else{
            return Promise.reject(new Error(result.msg))
      }
    },

    //获取提现验证码
    async getTXVerificationCode({commit},{mot}){
        let result = await getTXVerificationCode(mot)
        if(result.code==200){
              commit('GETTXVERIFICATIONCODE',result.data)
              return 'ok'
        }else{
              return Promise.reject(new Error(result.msg))
        }
    },

    //提现
    async withdrawal({commit},{money,mot,yzm}){
       let result = await withdrawal(money,mot,yzm)
       if(result.code==200){
              return 'ok'
       }else{
              return Promise.reject(new Error(result.msg))
       }
    }
}
const getters = {
    //获取用户信息
    //  userInfo(){
    //     return state.withdrawalRecordInfo.user||{}
    // }
}

export default{
    state,
    mutations,
    actions,
    getters
}