//路由配置信息
export default  [
    { path: '/', redirect: '/login' },//访问根地址时，重定向到/login
    { path: '/login',component:()=>import('@/pages/Login/login') },
    { path: '/register',component:()=>import('@/pages/Register/register') },
    { path: '/forget',component:()=>import('@/pages/Forget/forget') },
    {
        path: '/home',
        component:()=>import('@/pages/Home/home'),
        redirect:'/datamonitoring',
        children:[
            { path:'/detailed', component:()=>import('@/pages/Account_Management/accountDetails') },
            { path:'/publish', component:()=>import('@/pages/Task_Management/publish/publish') },
            { path:'/advancepayment', component:()=>import('@/pages/Task_Management/Advance_Payment/advancePayment') },
            { path:'/browse', component:()=>import('@/pages/Task_Management/browse') },
            { path:'/bindstore', component:()=>import('@/pages/Store_Management/bindStore') },
            { path:'/recharge', component:()=>import('@/pages/Balance_Management/recharge') },
            { path:'/withdrawal', component:()=>import('@/pages/Balance_Management/withdrawal') },
            { path:'/pricedetails', component:()=>import('@/pages/Price_Details/priceDetails') },
            { path:'/blacklist', component:()=>import('@/pages/Blacklist_Management/blacklist') },
            { path:'/appeal', component:()=>import('@/pages/Appeal/appeal') },
            { path:'/complaint', component:()=>import('@/pages/Complaint/complaint') },
            { path:'/datamonitoring', component:()=>import('@/pages/Data_Monitoring/dataMonitoring') },
            { 
                path:'/fillincommodityinfo',
                name:'fillincommodityinfo',
                component:()=>import('@/pages/Task_Management/publish/FillInCommodityInfo/fillInCommodityInfo'),
                beforeEnter:(to,from,next) => {
                    if(from.path=='/publish'){
                           next()
                    }else{
                           next(false)
                    }
                }
            },
            { 
                path:'/settlement',
                component:()=>import('@/pages/Task_Management/settlement/settlement'),
                beforeEnter:(to,from,next) => {
                    if(from.path=='/fillincommodityinfo'){
                           next()
                    }else{
                           next(false)
                    }
                }
             },
            { path:'/invitation',component:()=>import('@/pages/Invite_Friends/inviteFriends') },
            { path:'/taskdetails',component:()=>import('@/pages/Task_Management/Task_Details/taskDetails') },
        ]
    }
]





