import requests from '@/utils/request'

//发送验证码，
export const getSmscode = (code) => requests({ url: '/user/getshortmassage', method: 'post', data: code })

//账户密码登录
export const getUserLogin = (user) => requests({ url: '/user/login', method: 'post', data: user })

//手机验证码登录
export const getphoneLogin = (phone) => requests({ url: '/user/phoneLogin', method: 'post', data: phone })

//账号注册
export const getphoneRegister = (data) => requests({ url: '/user/register', method: 'post', data: data })

//找回密码
export const getphoneForget = (data) => requests({ url: '/user/resetPassword', method: 'post', data: data })

//获取头部信息
export const getHeaderInfo = () => requests({url:'/seller/header',method:'post'})

//获取左侧菜单列表
export const getMenuList = () => requests({url:'/seller/getmenu',method:'get'})

//获取我的店铺列表  `/seller/myshops/${token}/${num}/${pagenum}`   url:`/seller/myshops?token=${token}&num=${num}&page=${page}`
export const getStoreInfo = (num,page) => requests({url:'/seller/myshops',method:'post',data:{num,page}})

//获取绑定店铺地址
export const getBindStoreAddress = (shopsid) => requests({url:'/seller/seeaddress',method:'post',data:{shopsid}})

//获取省份数据
export const getProvinceData = () => requests({url:'/seller/getprovince',method:'post'})

//获取城市数据
export const getCityData = (bh) => requests({url:'/seller/getcity',method:'post',data:{bh}})

//获取地区数据
export const getAreaData = (bh) => requests({url:'/seller/gettown',method:'post',data:{bh}})

//获取平台名称数据
export const getPlatformName = () => requests({url:'seller/getplatform',method:'post'})

//添加绑定店铺
export const addBindStore = (data) => requests({url:'/seller/bindingShops',method:'post',data})

//修改复购间隔 
export const editRepurchaseInterval = (data) => requests({url:'/seller/domodifyagainbuy',method:'post',data})

//获取商铺发货信息
export const getStoreFHInfo = (shopsid) => requests({url:'/seller/getaddress',method:'post',data:{shopsid}})

//修改商铺发货信息
export const editStoreFHInfo = (data) => requests({url:'/seller/domodify',method:'post',data})

//获取黑名单数据
export const getBlacklist = (num,page) => requests({url:'/Blacklist/Blacklist',method:'post',data:{num,page}})

//移除黑名单
export const removeBlacklist = (buyerid) => requests({url:'/blacklist/relieve',method:'post',data:{buyerid}})

//获取价格明细数据
export const getPriceDetailsData = (type) => requests({url:'/price/priceinfo',method:'post',data:{type}})

//获取账户明细数据
export const getAccountDetailsData = (data) => requests({url:'/flow/flowList',method:'post',data})

//获取意见反馈(投诉)数据
export const getComplaintData = (num,page) => requests({url:'/seller/feedback',method:'post',data:{num,page}})

//获取上传反馈时的编号和图片数量
export const getBhAndLimit = () => requests({url:'/seller/feedbackpage',method:'post'})

//提交反馈(投诉)
export const complaintSubmit = (describe,bh) => requests({url:'/seller/feedbackSubmit',method:'post',data:{describe,bh}})

//关闭反馈（不提交）
export const  complaintNotSubmit = (bh) => requests({url:'/seller/closefeedback',method:'post',data:{bh}})

//删除反馈（投诉）已上传的图片
export const  deleteComplaintImage = (bh,id) => requests({url:'/seller/deleteimg',method:'post',data:{bh,id}})

//获取充值记录
export const getRechargeRecord = (num,page) => requests({url:'/recharge/rechargelist',method:'post',data:{num,page}})

//获取充值二维码和余额
export const getRechargeQRCode = () => requests({url:'/recharge/rechargeewm',method:'post'})

//二维码充值
export const QRCodeRecharge = (orderbh) => requests({url:'/recharge/recha',method:'post',data:{orderbh}})

//获取提现记录
export const getWithdrawalRecord = (num,page) =>  requests({url:'/seller/txlist',method:'post',data:{num,page}})

//获取绑定支付宝手机验证码
export const getVerificationCode = (mot) => requests({url:'/seller/sendali',method:'post',data:{mot}})

//绑定支付宝账户
export const submitBindingAlipay = (data) => requests({url:'/seller/binding',method:'post',data})

//获取提现手机验证码
export const getTXVerificationCode = (mot) => requests({url:'seller/sendtx',method:'post',data:{mot}})

//提现
export const withdrawal = (money,mot,yzm) => requests({url:'seller/tx',method:'post',data:{money,mot,yzm}})

//获取发布任务类型
export const  getPublishTaskType = () => requests({url:'/task/gettasktype1',method:'post'})

//切换任务类型
export const  changeTaskType = (id) => requests({url:'/task/gettasktype2',method:'post',data:{id}})

//获取所属任务下的店铺列表
export const  getTaskStoreList = (tasktypeid) => requests({url:'task/getshopslist',method:'post',data:{tasktypeid}})

//获取发布模块一的商品筛选信息
export const  getTaskCondition = () => requests({url:'task/gettaskcondition',method:'post'})

//提交发布模块一数据
export const  publishTaskFirstModuleSubmit = (data) => requests({url:'/task/taskrelease1',method:'post',headers:{'Content-Type':'multipart/form-data'},data})

//获取付费增值业务
export const  getPaidValueAddedServices = () => requests({url:'/task/paybusiness',method:'post'})

//提交发布第二模块数据
export const  publishTaskSecondModuleSubmit = (data) => requests({url:'/task/taskrelease2',method:'post',headers:{'Content-Type':'multipart/form-data'},data})

//提交发布第三块数据
export const  publishTaskThirdModuleSubmit = (data) => requests({url:'/task/taskrelease3',method:'post',headers:{'Content-Type':'multipart/form-data'},data})

//获取任务费用明细
export const getTaskCostDetails = (bh) => requests({url:'/paytask/ordertask',method:'post',data:{bh}})

//立即支付
export const orderPayment = (bh) => requests({url:'/paytask/orderpay',method:'post',data:{bh}})

//获取邀请信息
export const getInvitationInfo = () =>requests({url:'/seller/invitation',method:'post'})

//获取通知栏信息
export const getNotificationBarInfo = () => requests({url:'/notice/index',method:'post'})

//获取公告内容信息
export const getNotificationContent = (id) => requests({url:'/notice/content',method:'post',data:{id}})

//将所有通知设置为已读状态
export const allRead = () => requests({url:'/notice/allread',method:'post'})

//获取用户部分信息
export const getUserInfo = () =>requests({url:'/seller/userinfo',method:'post'})

//退出登录
export const logout = () => requests({url:'/user/loginout',method:'post'})

//获取垫付任务其他信息
export const getOtherInfo = () => requests({url:'/taskrecord/header',method:'post'})

//获取垫付任务信息
export const getAdvancePaymentInfo = (data) => requests({url:'/taskrecord/index',method:'post',data})

//删除未支付或审核失败的任务
export const deleteTask = (bh) => requests({url:'/taskrecord/deltask',method:'post',data:{bh}})

//拉黑
export const joinTheBlacklist = (userid) => requests({url:'/taskrecord/laheiuser',method:'post',data:{userid}})

//查看提交详情
export const viewSubmissionDetails = (id) => requests({url:'/taskrecord/details',method:'post',data:{id}})

//查看好评
export const viewReviews = (id) => requests({url:'/taskrecord/lookgood',method:'post',data:{id}})

//审核好评
export const toExamine = (taskbh,pass) => requests({url:'/taskrecord/shenhegood',method:'post',data:{taskbh,pass}})

//催评
export const urgeEvaluation = (taskbh) => requests({url:'/taskrecord/cuiping',method:'post',data:{taskbh}})

//处理结算暂停继续业务
export const handle = (taskbh,state) => requests({url:'/taskrecord/orderstate',method:'post',data:{taskbh,state}})

//获取任务详情数据
export const getTaskDetails = (data) => requests({url:'/taskrecord/orderdetails',method:'post',data})
