<template>
  <div class="title">
    <span class="line"></span>
    <p class="tit">{{title}}</p>
  </div>
</template>

<script>
export default {
   name: "Title",
   props:['title']
};
</script>

<style lang="less" scoped>
// @assets:"~@/assets";
// @import "@{assets}/css/theme.less";
.title {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  .line {
    width: 4px;
    height: 18px;
    // background: #4165f2;
    background: rgba(@themeColor,1);
  }
  .tit {
    font-size: 18px;
    margin-left: 10px;
    font-weight: 400;
    line-height: 30px;
    color:@themeTextColor;
  }
}
</style>