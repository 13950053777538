import { getOtherInfo,getAdvancePaymentInfo,deleteTask,joinTheBlacklist,viewSubmissionDetails,viewReviews,toExamine,urgeEvaluation,handle,getTaskDetails } from '@/api'

const state = {
    otherInfo:{},
    advancePaymentInfo:{},
    viewSubmissionInfo:{},
    viewReviewsInfo:{},
    taskDetailsData:{}
}

const mutations = {
    GETOTHERINFO(state,otherInfo){
        state.otherInfo = otherInfo
    },
    GETADVANCEPAYMENTINFO(state,advancePaymentInfo){
          state.advancePaymentInfo = advancePaymentInfo
    },
    VIEWSUBMISSIONDETAILS(state,viewSubmissionInfo){
          state.viewSubmissionInfo = viewSubmissionInfo
    },
    VIEWREVIEWS(state,viewReviewsInfo){
          state.viewReviewsInfo = viewReviewsInfo
    },
    GETTASKDETAILS(state,taskDetailsData){
          state.taskDetailsData = taskDetailsData
    } 
}

const actions = {
    async getOtherInfo({commit}){//获取垫付任务其他信息
        let result = await getOtherInfo()
        if(result.code==200){
             commit('GETOTHERINFO',result.data)
             return 'ok'
        }else{
             return Promise.reject(new Error(result.msg))
        }
   },

    async getAdvancePaymentInfo({commit},data){//获取垫付任务信息
         let result = await getAdvancePaymentInfo(data)
         if(result.code==200){
              commit('GETADVANCEPAYMENTINFO',result.data)
              return 'ok'
         }else{
              return Promise.reject(new Error(result.msg))
         }
    },

    async deleteTask({commit},bh){//删除未支付或审核失败的任务
        let result = await deleteTask(bh)
        if(result.code==200){
             return 'ok'
        }else{
             return Promise.reject(new Error(result.msg))
        }
    },

    async joinTheBlacklist({commit},userid){//拉黑
       let result = await joinTheBlacklist(userid)
       if(result.code==200){
             return 'ok'
       }else{
            return Promise.reject(new Error(result.msg))
       }
    },

    async viewSubmissionDetails({commit},id){//查看提交详情
       let result = await viewSubmissionDetails(id)
       if(result.code==200){
             commit('VIEWSUBMISSIONDETAILS',result.data)
             return 'ok'
       }else{
            return Promise.reject(new Error(result.msg))
       }
    },


    async viewReviews({commit},id){//查看进行中好评信息
       let result = await viewReviews(id)
       if(result.code==200){
             commit('VIEWREVIEWS',result.data)
             return 'ok'
       }else{
            return Promise.reject(new Error(result.msg))
       }
    },

    async toExamine({commit},{taskbh,pass}){//审核好评
       let result = await toExamine(taskbh,pass)
       if(result.code==200){
             return 'ok'
       }else{
            return Promise.reject(new Error(result.msg))
       }
    },

    async urgeEvaluation({commit},taskbh){//审核好评
       let result = await urgeEvaluation(taskbh)
       if(result.code==200){
             return 'ok'
       }else{
            return Promise.reject(new Error(result.msg))
       }
    },

    async handle({commit},{taskbh,state}){//审核好评
       let result = await handle(taskbh,state)
       if(result.code==200){
             return 'ok'
       }else{
            return Promise.reject(new Error(result.msg))
       }
    },

    async getTaskDetails({commit},data){//获取任务详情数据
       let result = await getTaskDetails(data)
       console.log(result)
       if(result.code==200){
            commit('GETTASKDETAILS',result.data)
            return 'ok'
       }else{
           return Promise.reject(new Error(result.msg))
       }
    },
}

const getters = {
    
}

export default {
    state,
    mutations,
    actions,
    getters
}