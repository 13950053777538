<template>
  <div>
    <!--面包屑导航区-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <i class="haowu icon" :class="[changIcon ? 'hw-zhedieyou':'hw-zhediezuo']"  @click="toggleCollapse"></i>
      <el-breadcrumb-item  v-for="item in breadcrumbData" :key="item.id"> 
          <router-link v-if="item.isClick==true" :to="{ path: '/' + item.path}" class="link">{{ item.title }}</router-link>
          <span v-else>{{ item.title }}</span>
      </el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script>
export default {
  name: "Breadcrumb",
  props: ["breadcrumbData"],
  data() {
    return {
      //图标切换
      changIcon: false
    };
  },
  methods: {
    toggleCollapse() {
      this.changIcon = !this.changIcon;
    }
  },
  watch:{
      changIcon:{
            immediate:true,
            handler(newValue,oldValue){
                this.$bus.$emit("toggleCollapse",newValue);
            }
      }
  }
  
};
</script>

<style lang="less" scoped>
// @assets:"~@/assets";
// @import "@{assets}/css/theme.less";
.el-breadcrumb {
  height: 50px;
  background: #ffffff;
  padding-left: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor:default;
  .icon {
    margin-right: 10px;
    // color: #4a5064;
    color:rgba(@themeColor,1);
  }
}
.link{
  //  color:#4165f2 !important;
   color:rgba(@themeColor,1) !important;
   cursor: pointer
}
 
</style>